import request from '@/plugins/axios'

// 获取歌曲详情
export const loadMusicInfoData = (id: string): any =>
  request({
    url: `/v2/music/${id}`,
    method: 'get',
  })

// 获取资产详情
export const loadDigitalInfoData = (id: string): any =>
  request({
    url: `/v2/digital/${id}`,
    method: 'get',
  })

// 提交转账信息
export const sendDigitalTransfer = (data: { id: number; amount: number }): any =>
  request({
    url: `/v2/digital/tx`,
    method: 'post',
    data,
  })

// 获取订单详情
export const loadOrderData = (order: string): any =>
  request({
    url: `/v2/order/${order}`,
    method: 'get',
  })

interface IReceive {
  order_no: string
  amount: number
  from_uid: number
  wid: number
  end_time: number
  sign: string
}

// 领取转账
export const receiveDigital = (data: IReceive): any =>
  request({
    url: `/v2/digital/claim`,
    method: 'post',
    data,
  })

// 转账明细
export const loadDetailedData = (params: { page_size: number; offset: number; wid?: number }): any =>
  request({
    url: `/v2/orders`,
    method: 'get',
    params,
  })
